import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import Box from 'components/box';
// import Head from 'components/head';
// import Registration from 'components/registration';
// import Registration from 'components/forms/reports/risk-report';
import Registration from "../components/forms/demo/Form"
import { Link } from "gatsby"

import SubscribeCTA from "../components/subscribe-cta"

const RequestDemo = () => (
  <div>
    <SEO title="Request Demo" />
    <Layout>
      <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">
            Request a demo with us today!{" "}
            {/* <span style={{ color: '#007bff' }}>
              Get solutions for your digital trust and privacy posture today.
            </span> */}
          </h1>
        </div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-md-8 content">
            <div className="container container-fluid">
              <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                  <div
                    className="span12 widget-span widget-type-custom_widget "
                    data-widget-type="custom_widget"
                    data-x="0"
                    data-w="12"
                  >
                    <div
                      id="hs_cos_wrapper_module_1559559069417869"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <div className="lp-hero-mod" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                <div className="row-fluid ">
                  <div
                    className="span12 widget-span widget-type-cell main-body-content"
                    data-widget-type="cell"
                    data-x="0"
                    data-w="12"
                  >
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell page-center rrLpbox"
                          data-widget-type="cell"
                          data-x="0"
                          data-w="12"
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                            <div className="row-fluid ">
                              <div
                                className="span8 widget-span widget-type-widget_container lp-left-col"
                                data-widget-type="widget_container"
                                data-x="0"
                                data-w="8"
                              >
                                <span
                                  id="hs_cos_wrapper_module_1559560058107891"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
                                  data-hs-cos-general-type="widget_container"
                                  data-hs-cos-type="widget_container"
                                >
                                  <div
                                    id="hs_cos_wrapper_widget_1559560045578888"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <div className="full-width-content-mod paddingtb30 max-content-width  ">
                                      <div className="page-center">
                                        <div className="section-header">
                                          <h2>
                                            See the TheLeanFounder difference
                                            in action
                                          </h2>
                                        </div>

                                        <div className="section-content bottom-divider">
                                          <p>
                                            TheLeanFounder enables you to
                                            monitor third-party (and their
                                            vendors’) privacy risk based on
                                            Internet presence and other
                                            data aggregated over time into an
                                            immutable trustable ledger. Our
                                            unique risk-prioritized action plans
                                            rely on advanced models and
                                            analytics to prioritize by issue
                                            severity.{" "}
                                          </p>
                                          <p>
                                            TheLeanFounder creates its own
                                            privacy measurements and reports
                                            comprising more than 170 unique
                                            criteria, for the most accurate and
                                            broad picture of risk along with
                                            other information to provide a
                                            comprehensive solution.
                                          </p>
                                          <p>
                                            TheLeanFounder closely aligns with
                                            current frameworks and standards and
                                            will keep on top of changing
                                            landscape of best practices to
                                            ensure data and reports are
                                            reflecting the most current
                                            standards.
                                          </p>
                                          <p>
                                            TheLeanFounder works with and
                                            suggests as needed recommendations
                                            and potential solutions via approved
                                            and certified third party partners
                                            (assessors, auditors, solution
                                            providers and managed service
                                            providers) that are highly suited to
                                            help organiztions and teams
                                            remediate risks and gaps.
                                          </p>
                                          <p>
                                            TheLeanFounder finds risks that you
                                            may not have known were there. We
                                            take it a step further by not just
                                            identifying those risks but also by
                                            helping companies understand and
                                            solve them. TheLeanFounder helps
                                             manage risk through customized
                                            action plans, in-depth scores, and actionable insights.
                                          </p>
                                          <p>
                                            Sign up for a demo to see for
                                            yourself why TheLeanFounder is the
                                            only solution for managing 
                                            digital trust reputation and privacy
                                            risk at scale.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="hs_cos_wrapper_widget_1582320669771"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  />
                                </span>
                              </div>
                              <div
                                className="span4 widget-span widget-type-cell lp-right-col"
                                data-widget-type="cell"
                                data-x="8"
                                data-w="4"
                              />
                            </div>
                          </div>

                          <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-widget_container "
                                data-widget-type="widget_container"
                                data-x="0"
                                data-w="12"
                              >
                                <span
                                  id="hs_cos_wrapper_module_1573039922314103"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
                                  data-hs-cos-general-type="widget_container"
                                  data-hs-cos-type="widget_container"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <br />
            <br />
            {/* <Registration /> */}
            <SubscribeCTA title="Request Free Demo" />
          </div>{" "}
        </div>{" "}
      </div>
    </Layout>
  </div>
)

export default RequestDemo
